"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const DOMSelectorCleaner_1 = require("src/projects/Widget/utils/DOMSelectorCleaner");
class GatewayAbstract extends DOMSelectorCleaner_1.DOMSelectorCleaner {
    constructor(parent) {
        console.log("[GatewayAbstract.constructor]");
        super();
        const self = this;
        self.parent = parent;
    }
    createIframe() {
        const iframe = document.createElement("iframe");
        iframe.id = this.settings.iframe.id;
        iframe.className = this.settings.iframe.className;
        iframe.allow = this.settings.iframe.allow;
        iframe.src = `${this.settings.iframe.src}?v=${RELEASE_VERSION}`;
        iframe.style.cssText = this.settings.iframe.style;
        if (this.settings.iframe.attributes) {
            Object.keys(this.settings.iframe.attributes).map((key) => {
                iframe.setAttribute(key, this.settings.iframe.attributes[key]);
            });
        }
        this.$("body").appendChild(iframe);
        this.iframe = iframe;
    }
    postMessage(data, targetOrigin = "*") {
        console.log(`[${this.constructor.name}.postMessage] ${data.type}`);
        this.iframe.contentWindow.postMessage(data, targetOrigin);
    }
}
exports.default = GatewayAbstract;
