"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.removeLocalStorageItem = exports.setLocalStorageItem = exports.getLocalStorageItem = exports.useLocalStorage = void 0;
var localStorageDefaults;
(function (localStorageDefaults) {
    localStorageDefaults["language"] = "ru";
    localStorageDefaults["theme"] = "light";
    localStorageDefaults["constructor_side"] = "right";
})(localStorageDefaults || (localStorageDefaults = {}));
function useLocalStorage(name, value) {
    const storedValue = (0, exports.getLocalStorageItem)(name);
    const defaultValue = localStorageDefaults[name];
    if (value) {
        (0, exports.setLocalStorageItem)(name, value);
        return (0, exports.getLocalStorageItem)(name);
    }
    else if (storedValue) {
        return storedValue;
    }
    else {
        (0, exports.setLocalStorageItem)(name, defaultValue);
        return (0, exports.getLocalStorageItem)(name);
    }
}
exports.useLocalStorage = useLocalStorage;
const getLocalStorageItem = (name) => {
    const item = window.localStorage.getItem(name);
    return item;
};
exports.getLocalStorageItem = getLocalStorageItem;
const setLocalStorageItem = (name, value) => {
    return window.localStorage.setItem(name, value);
};
exports.setLocalStorageItem = setLocalStorageItem;
const removeLocalStorageItem = (name) => {
    window.localStorage.removeItem(name);
};
exports.removeLocalStorageItem = removeLocalStorageItem;
