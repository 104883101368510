"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toursteps2hash = exports.accumulatorSort = exports.sortStatusOrder = void 0;
const helpers_1 = require("src/projects/Constructor/helpers/helpers");
exports.sortStatusOrder = {
    published: 0,
    changed: 1,
    not_published_changed: 2,
    not_published: 3,
};
const statusSort = (a, b) => {
    const firstStatus = (0, helpers_1.getItemStatus)(a);
    const secondStatus = (0, helpers_1.getItemStatus)(b);
    return exports.sortStatusOrder[firstStatus] - exports.sortStatusOrder[secondStatus];
};
const nameSort = (a, b) => {
    return a.draft.name.localeCompare(b.draft.name);
};
const SORT_MATCHING = {
    status: statusSort,
    name: nameSort,
};
const accumulatorSort = (data, fields = ["status", "name"]) => {
    if (!data || !data.length)
        return [];
    return data.sort((a, b) => {
        return fields.reduce((acc, condition) => {
            if (!SORT_MATCHING[condition])
                return acc;
            return acc = acc || SORT_MATCHING[condition](a, b);
        }, null);
    });
};
exports.accumulatorSort = accumulatorSort;
const toursteps2hash = (steps) => {
    const result = steps.reduce((a, v) => (Object.assign(Object.assign({}, a), { [v.id]: v })), {});
    const zero_step = steps.filter((item) => !item.prev_step)[0];
    if (zero_step)
        result[0] = zero_step;
    return result;
};
exports.toursteps2hash = toursteps2hash;
