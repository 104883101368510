"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomElement = void 0;
const DOMSelectorCleaner_1 = require("src/projects/Widget/utils/DOMSelectorCleaner");
class CustomElement extends HTMLElement {
    constructor() {
        console.log("[CustomElement.constructor]");
        super();
        this.$ = new DOMSelectorCleaner_1.DOMSelectorCleaner().$;
        this.attachShadow({ mode: "open" });
    }
    appendStyles() {
        console.log("[CustomElement.appendStyles]");
        const styleTag = document.createElement("style");
        styleTag.textContent = this.styles;
        return this.shadowRoot.appendChild(styleTag);
    }
    connectedCallback() {
        this.appendStyles();
        this.createElements();
    }
}
exports.CustomElement = CustomElement;
