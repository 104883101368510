"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TOOLTIP_STYLES = void 0;
exports.TOOLTIP_STYLES = `
    .hotspot-wrapper {
        position: absolute;
        top: 50%;
        bottom: 50%;
        margin-left: 15px;
        display: block;
        width: 15px;
        height: 15px;
        transform: translate(-50%, -50%);
    }

    .aw-info {
        font-size: 17px;
        display: none;
        position: absolute;
        height: auto;
        z-index: 3;
        box-sizing: content-box;
        left: 5px;
        top: 50%;
        bottom: 50%;
        align-items: center;
        min-height: 30px;
        background: #FFFFFF;
        border-radius: 8px;
        transform: translate(25px, -50%);
        width: 500px;
    }

    .aw-popup-visible {
        opacity: 1;
        visibility: visible;
    }

    .aw-info.aw-popup-visible {
        display: flex;
    }

    .aw-info-arrow-position {
        display: flex;
        position: relative;
        height: 100%;
    }

    .aw-info-arrow {
        position: absolute;
        left: 0;
        box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.15), 0px 0px 1px -1px rgba(0, 0, 0, 0.4);
    }

    .aw-info-arrow::before {
        position: absolute;
        top: 50%;
        bottom: 50%;
        transform: translate(-7px, -50%);
        display: block;
        content: "";
        border-color: transparent;
        border-style: solid;
        border-right-color: #C8C8C8;
        border-width: 7px 7px 7px 0px;
    }

    .aw-info-arrow::after {
        position: absolute;
        top: 50%;
        bottom: 50%;
        transform: translate(-6.5px, -50%);
        display: block;
        content: "";
        border-color: transparent;
        border-style: solid;
        border-right-color: #FFFFFF;
        border-width: 7px 7px 7px 0px;
        left: 1px;
    }

    .aw-info-body {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        
        max-width: 200px;
        background: #FFFFFF;
        font-size: 14px;
        line-height: 17px;
        
        color: #343A40;
        font-family: 'Arial';
        box-sizing: content-box;
        border-radius: 8px;
        padding: 12px 16px;
        box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.15), 0px 0px 1px -1px rgba(0, 0, 0, 0.4);
        border: 1px solid #C8C8C8;
    }

    .adopty-overlay-clickable {
        position: fixed;
        cursor: pointer;
        z-index: 100000;
    }
`;
