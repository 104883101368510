"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdoptyTooltip = void 0;
const HotspotsMain_1 = require("src/projects/Widget/shadowDOM/hotspots/HotspotsMain");
const tooltipStyles_1 = require("src/projects/Widget/styles/tooltipStyles");
const Tooltip_1 = require("src/projects/Widget/mainDOM/Tooltip");
class AdoptyTooltip extends HotspotsMain_1.HotspotsMain {
    constructor(parent, item) {
        console.log("[AdoptyTooltip.constructor]");
        super(parent, item);
        this.styles = this.styles.concat(tooltipStyles_1.TOOLTIP_STYLES);
    }
    createElements() {
        console.log("[AdoptyTooltip.createElements]");
        this.triggerEl = document.createElement("div");
        this.triggerEl.setAttribute("data-aw", "aw-hotspot");
        this.triggerEl.setAttribute("data-icon", this.item.icon);
        this.container_element = document.createElement("div");
        this.container_element.classList.add("hotspot-wrapper");
        this.container_element.appendChild(this.triggerEl);
        this.tooltip = new Tooltip_1.Tooltip(this.container_element, this.triggerEl, {
            content: this.item.content,
            show_on: this.item.show_on,
        });
        this.shadowRoot.appendChild(this.container_element);
    }
    connectedCallback() {
        this.setAttribute("data-show_on", this.item.show_on);
        super.connectedCallback();
    }
    updateItem(target, item) {
        const oldItem = this.item;
        this.item = item;
        if (oldItem.element != item.element) {
            this.updateParent(target);
        }
        this.setAttribute("data-show_on", this.item.show_on);
        this.triggerEl.setAttribute("data-icon", this.item.icon);
        this.tooltip.updateTooltipData({
            content: item.content,
            show_on: item.show_on,
        });
    }
    removeElement() {
        this.tooltip.unbindEvents();
        super.removeElement();
    }
}
exports.AdoptyTooltip = AdoptyTooltip;
