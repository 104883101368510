"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const sharedUtils_1 = require("src/utils/sharedUtils");
const utils_1 = require("src/utils/utils");
const DOMSelectorCleaner_1 = require("src/projects/Widget/utils/DOMSelectorCleaner");
const AdoptyModal_1 = require("src/projects/Widget/shadowDOM/tours/AdoptyModal");
const AdoptyPopover_1 = require("src/projects/Widget/shadowDOM/tours/AdoptyPopover");
const serializers_1 = require("src/stores/constructorStore/hotspots/serializers");
class TourController extends DOMSelectorCleaner_1.DOMSelectorCleaner {
    get isDemoMode() {
        return this._isDemoMode;
    }
    set isDemoMode(value) {
        this._isDemoMode = value;
        this.parent.localStorageController.set("isDemoMode", value);
    }
    constructor(parent) {
        var _a;
        console.log("[TourController.constructor]");
        super();
        this.DELAY_TIMEOUT = 300;
        this.ELEMENT_WAIT_LIMIT = 20;
        this.conditionFunc = {
            device: (condition) => this.runStepConditionDevice(condition),
            user_property: (condition) => this.runStepConditionUserProperty(condition),
        };
        this.runStepConditionUserProperty = (condition) => {
            var _a;
            const user = this.parent.user;
            if (user && (condition === null || condition === void 0 ? void 0 : condition.test_param)) {
                const user_value = ((_a = user === null || user === void 0 ? void 0 : user.visitor) === null || _a === void 0 ? void 0 : _a.last_params[condition.test_param]) || "";
                if (["equal", "in_list"].indexOf(condition.test_how) != -1)
                    return user_value == condition.test_against;
                if (["not_equal", "not_in_list"].indexOf(condition.test_how) != -1)
                    return user_value != condition.test_against;
                if (condition.test_how == "greater")
                    return user_value > condition.test_against;
                if (condition.test_how == "less")
                    return user_value < condition.test_against;
            }
            return false;
        };
        this.runStepConditionDevice = (condition) => {
            const screenWidth = this.$("body").clientWidth;
            const screenType = screenWidth <= 600 ? "mobile" : screenWidth <= 1024 ? "tablet" : "desktop";
            if (condition.test_how == "in_list")
                return screenType == condition.test_against;
            if (condition.test_how == "not_in_list")
                return screenType != condition.test_against;
            if (condition.test_how == "greater_in_list") {
                const list = {
                    mobile: ["tablet", "desktop"],
                    tablet: ["desktop"],
                    desktop: [],
                }[condition.test_against];
                return (list || []).indexOf(screenType) >= 0;
            }
            if (condition.test_how == "less_in_list") {
                const list = {
                    mobile: [],
                    tablet: ["mobile"],
                    desktop: ["mobile", "tablet"],
                }[condition.test_against];
                return (list || []).indexOf(screenType) >= 0;
            }
            return false;
        };
        this.closeTour = () => {
            console.log("[TourController.closeTour]");
            const nextStepId = this.getNextStepId();
            if (nextStepId) {
                this.parent.widget.track_tour(this.currentTour.id, {
                    step: this.currentStepId,
                    step_num: this.currentSteps[this.currentStepId].num,
                    step_num_max: this.currentTour.maxStepNum,
                    closed: true,
                    autostart: this.isAutoStart,
                });
            }
            this.stopTour();
        };
        this.stopTour = () => {
            console.log("[TourController.stopTour]");
            if (this.shownTourStep)
                this.shownTourStep.removeElement();
            this.currentTour = null;
            this.currentSteps = null;
            this.currentStepId = null;
            this.element_wait_count = 0;
            this.isAutoStart = false;
            this.isDemoMode = false;
            this.clear_stored_tour_step();
        };
        this.goNextStep = (is_false_branch = false) => {
            console.log("[TourController.goNextStep]", is_false_branch);
            this.element_wait_count = 0;
            const nextStepId = this.getNextStepId(is_false_branch);
            if (!this.currentSteps[nextStepId]) {
                this.stopTour();
                return;
            }
            this.currentStepId = nextStepId;
            this.showStep();
        };
        this.goPreviousStep = () => {
            console.log("[TourController.goPreviousStep]");
            const step = this.currentSteps[this.currentStepId];
            this.currentStepId = step.prev_step;
            const newStep = this.currentSteps[this.currentStepId];
            if (!newStep) {
                this.stopTour();
                return;
            }
            if (newStep.hidden || newStep.type == "condition") {
                return this.goPreviousStep();
            }
            this.isPrevDirection = true;
            this.element_wait_count = 0;
            this.showStep();
        };
        this.startTour = ({ id = null, step = null, demoMode = false, autoStart = false }) => {
            var _a, _b;
            console.log("[TourController.startTour] with initial data as id = ", id, " and step ", step);
            if (id)
                this.isDemoMode = demoMode;
            this.isAutoStart = autoStart;
            this.isPrevDirection = false;
            this.element_wait_count = 0;
            const currentTourId = id || ((_a = this.parent.localStorageController.get("tour")) === null || _a === void 0 ? void 0 : _a.value) || null;
            this.currentStepId = (0, utils_1.isDefined)(step) ? step : ((_b = this.parent.localStorageController.get("step")) === null || _b === void 0 ? void 0 : _b.value) || 0;
            console.log("[TourController.startTour] with id = ", currentTourId, " and step ", this.currentStepId);
            this.currentTour = this.parent.tours.find((item) => item.id == currentTourId);
            if (!this.currentTour) {
                console.log("[TourController.startTour] Tour not found. Stop");
                return this.stopTour();
            }
            this.currentSteps = (0, serializers_1.toursteps2hash)(this.isDemoMode ? this.currentTour.draft.steps : this.currentTour.steps);
            if (!this.currentSteps[0]) {
                console.log("[TourController.startTour] Zero step not found. Stop");
                return this.stopTour();
            }
            if (this.currentStepId >= 0) {
                this.parent.localStorageController.set("tour", currentTourId);
                this.prepareTour();
                this.showStep();
            }
        };
        this.parent = parent;
        this._isDemoMode = ((_a = this.parent.localStorageController.get("isDemoMode")) === null || _a === void 0 ? void 0 : _a.value) || false;
        document.addEventListener("keydown", (event) => {
            if (this.currentTour && (event.key === "F5" || (event.metaKey && event.key === "r"))) {
                console.log("[TourController.constructor] Browser refresh. Close tour");
                this.closeTour();
            }
        });
    }
    runStepCondition(data) {
        let need_stop = false;
        const values = data.conditions.map((v) => {
            if (need_stop)
                return;
            if (v.type == "single") {
                const condition = v;
                if (condition.test_what == "user_property" && (!this.parent.user || !condition.test_param)) {
                    need_stop = true;
                    return;
                }
                return this.conditionFunc[condition.test_what](condition);
            }
            else {
                const condition = v;
                const res = this.runStepCondition(condition);
                if ((0, utils_1.isNull)(res)) {
                    need_stop = true;
                    return;
                }
                return res;
            }
        });
        if (need_stop)
            return null;
        return data.logic == "or"
            ? values.filter((v) => !!v).length > 0
            : values.filter((v) => !v).length == 0;
    }
    canTourAutostart(tour) {
        if (!tour.autostart || tour.start_page != (0, sharedUtils_1.getCurrentPage)())
            return false;
        const user = this.parent.user;
        if (user && tour.run_rate == "once" && user.tour_view[tour.id])
            return false;
        const steps = (0, serializers_1.toursteps2hash)(tour.steps);
        return this.hasNextStep(steps, steps[0]);
    }
    autostartTour() {
        console.log("[TourController.autostartTour]");
        const start = this.parent.tours.find((item) => {
            if (this.canTourAutostart(item)) {
                return item;
            }
        });
        if (start) {
            console.log("[TourController.autostartTour] Found autostart tour ", start.id);
            this.startTour({ id: start.id, step: 0, autoStart: true });
        }
    }
    clear_stored_tour_step() {
        console.log("[TourController.clear_stored_tour_step]");
        this.parent.localStorageController.delete("tour");
        this.parent.localStorageController.delete("step");
    }
    hasNextStep(steps = this.currentSteps, step) {
        let check_step = step;
        while (check_step) {
            if (check_step.type == "condition" &&
                this.runStepCondition(check_step.data)) {
                check_step = steps[check_step.next_step];
            }
            else if (check_step.type == "condition") {
                check_step = steps[check_step.next_step_false];
            }
            else if (check_step.hidden) {
                check_step = steps[check_step.next_step];
            }
            else {
                return true;
            }
        }
        return false;
    }
    hasPrevStep(steps = this.currentSteps, step) {
        let check_step = step;
        while (check_step) {
            if (check_step.type == "condition") {
                check_step = steps[check_step.prev_step];
            }
            else if (check_step.hidden) {
                check_step = steps[check_step.prev_step];
            }
            else {
                return check_step;
            }
        }
        return null;
    }
    getNextStepId(is_false_branch = false) {
        const step = this.currentSteps[this.currentStepId];
        return is_false_branch ? step.next_step_false : step.next_step;
    }
    showStep() {
        var _a;
        console.log(`[TourController.showStep] Step ${this.currentStepId}`);
        if (this.shownTourStep)
            this.shownTourStep.removeElement();
        const stepData = this.currentSteps[this.currentStepId];
        if (!stepData) {
            console.log("[TourController.showStep] No step, stop");
            return this.stopTour();
        }
        this.currentStepId = stepData.id; // Не шаг 0, то ставим реальный ИД
        this.parent.localStorageController.set("step", this.currentStepId);
        if (stepData.type != "condition" && stepData.on_page && stepData.on_page !== (0, sharedUtils_1.getCurrentPage)()) {
            console.log(`[TourController.showStep] redirect, current '${(0, sharedUtils_1.getCurrentPage)()}', need '${stepData.on_page}'`);
            (0, sharedUtils_1.redirect)(stepData.on_page);
            if ((0, sharedUtils_1.getCurrentPage)().replace(/#.*/, "") != stepData.on_page.replace(/#.*/, "") ||
                (/#/.test((0, sharedUtils_1.getCurrentPage)()) && !/#/.test(stepData.on_page)))
                return;
        }
        if (stepData.hidden) {
            console.log("[TourController.showStep] Step hidden, skip");
            return this.goNextStep();
        }
        const hasNextStep = this.hasNextStep(this.currentSteps, this.currentSteps[stepData.next_step]);
        const hasPrevStep = this.hasPrevStep(this.currentSteps, this.currentSteps[stepData.prev_step]);
        const params = {
            onClose: this.closeTour,
            isFinish: !hasNextStep,
        };
        // Следующий шаг есть всегда, только иногда он завершающий
        if (stepData.type != "condition")
            params["onNext"] = this.goNextStep;
        if (stepData.type != "condition" && hasPrevStep)
            params["onPrevious"] = this.goPreviousStep;
        if (stepData.type == "tooltip") {
            const step = stepData;
            const target = this.$(step.data.element);
            if (!target) {
                if (hasPrevStep &&
                    hasPrevStep.type == "tooltip" &&
                    ((_a = hasPrevStep === null || hasPrevStep === void 0 ? void 0 : hasPrevStep.data) === null || _a === void 0 ? void 0 : _a.next_onevent) == "click_element" &&
                    this.element_wait_count < this.ELEMENT_WAIT_LIMIT) {
                    ++this.element_wait_count;
                    console.log("[TourController.showStep] No target, wait tick", this.element_wait_count);
                    setTimeout(() => {
                        this.showStep();
                    }, this.DELAY_TIMEOUT);
                    return;
                }
                else {
                    console.log("[TourController.showStep] No target, skip");
                    return this.goNextStep();
                }
            }
            this.shownTourStep = new AdoptyPopover_1.AdoptyPopover(target, step, params);
        }
        else if (stepData.type == "modal") {
            const step = stepData;
            this.shownTourStep = new AdoptyModal_1.AdoptyModal(step, params);
        }
        else if (stepData.type == "condition") {
            const step = stepData;
            const res = this.runStepCondition(step.data);
            if ((0, utils_1.isNull)(res)) {
                console.log("[TourController.showStep] Run condition fail, stop");
                return this.stopTour();
            }
            if (res) {
                this.goNextStep();
            }
            else {
                this.goNextStep(true);
            }
            return true;
        }
        if (this.shownTourStep) {
            console.log(`[TourController.showStep] Show step ${stepData.num} / ${this.currentTour.maxStepNum}`);
            // Не трекаем шаг с условием
            if (!this.isPrevDirection)
                this.parent.widget.track_tour(stepData.tour, {
                    step: stepData.id,
                    step_num: stepData.num,
                    step_num_max: this.currentTour.maxStepNum,
                    finished: !hasNextStep,
                    autostart: this.isAutoStart,
                });
            this.isPrevDirection = false;
            this.element_wait_count = 0;
            this.$("body").appendChild(this.shownTourStep);
        }
        else {
            console.log("[TourController.showStep] Not created element, skip");
            return this.goNextStep();
        }
    }
    // Считаем шаги
    // TODO: повторяется логика из hasNextStep. Можно уйти от has_*, а сразу помечать есть ли шаг, можно убирать next_id из шага
    prepareTour() {
        console.log("[TourController.prepareTour]");
        let check_step = this.currentSteps[0];
        let num = 0;
        while (check_step) {
            if (check_step.type == "condition" &&
                this.runStepCondition(check_step.data)) {
                check_step = this.currentSteps[check_step.next_step];
            }
            else if (check_step.type == "condition") {
                check_step = this.currentSteps[check_step.next_step_false];
            }
            else if (check_step.hidden) {
                check_step = this.currentSteps[check_step.next_step];
            }
            else {
                check_step.num = ++num;
                check_step = this.currentSteps[check_step.next_step];
            }
        }
        this.currentTour.maxStepNum = num;
    }
}
exports.default = TourController;
