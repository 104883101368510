"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOMSelectorCleaner = void 0;
class DOMSelectorCleaner {
    constructor() {
        this.$ = (element) => {
            try {
                return document.querySelector(this.cleanSelector(element));
            }
            catch (_a) {
                if (typeof element === "object")
                    return element;
                return null;
            }
        };
    }
    static escapeValue(string) {
        return string.replace(/\s+/g, ".").replace(/[:*+?^${}()|[\]\\]/gi, "\\$&");
    }
    cleanSelector(selector) {
        if (!selector)
            return;
        (selector.match(/(#[0-9][^\s:,]*)/g) || []).forEach((n) => {
            selector = selector.replaceAll(n, '[id="' + n.replaceAll("#", DOMSelectorCleaner.escapeValue(n)) + '"]');
        });
        return selector;
    }
}
exports.DOMSelectorCleaner = DOMSelectorCleaner;
