"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LocalStorageController = void 0;
class LocalStorageController {
    constructor(options = { periodInMs: 60000, autoremove: false }) {
        this.autoremoveFromStorage = () => {
            console.log("[LocalStorageController.autoremoveFromStorage]");
            const ctx = this;
            const availableStorageItems = ["step", "tour"];
            availableStorageItems.map((item) => {
                if (!window.localStorage.getItem(item))
                    return;
                const storageItem = ctx.get(item);
                if (!(storageItem === null || storageItem === void 0 ? void 0 : storageItem.lifetime))
                    return;
                if (Date.now() - storageItem.lifetime * 1000 * 60 < storageItem.timestamp)
                    return;
                ctx.delete(item);
            });
        };
        console.log("[LocalStorageController.constructor]");
        const ctx = this;
        if (!window.localStorage.getItem("adopty")) {
            console.log("[LocalStorageController.constructor] Init store");
            window.localStorage.setItem("adopty", JSON.stringify({}));
        }
        // ctx.localStorageItem = JSON.parse(window.localStorage.getItem("adopty")) || {};
        if (options.autoremove) {
            ctx.autoremoveFromStorage();
            setInterval(ctx.autoremoveFromStorage, options.periodInMs);
        }
    }
    getGlobalStorageItem() {
        return JSON.parse(window.localStorage.getItem("adopty"));
    }
    get(name) {
        console.log("[LocalStorageController.get] ", name);
        // if (this?.localStorageItem?.[name]) return this.localStorageItem[name]
        const localStorage = this.getGlobalStorageItem();
        if (localStorage[name])
            return localStorage[name];
        return null;
    }
    set(name, value, lifetimeInMinutes) {
        console.log("[LocalStorageController.set]", name, value);
        const localStorage = this.getGlobalStorageItem();
        const data = { value, timestamp: Date.now(), lifetime: lifetimeInMinutes };
        return window.localStorage.setItem("adopty", JSON.stringify(Object.assign(Object.assign({}, localStorage), { [name]: data })));
    }
    delete(name) {
        console.log("[LocalStorageController.delete] ", name);
        const localStorage = this.getGlobalStorageItem();
        delete localStorage[name];
        return window.localStorage.setItem("adopty", JSON.stringify(localStorage));
    }
}
exports.LocalStorageController = LocalStorageController;
