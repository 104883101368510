"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isDev = exports.dev_env_object = exports.testUrl = exports.wrightToClipboard = exports.access = exports.getQueryParams = exports.getUTMs = exports.matchProperties = exports.getInitials = exports.linebreak2br = exports.capitalizeFirstLetter = exports.urlMatch = exports.getCurrentPage = exports.serializeForm = exports.getCookie = exports.redirect = void 0;
const permissions_1 = require("./permissions");
const redirect = (data) => {
    console.log("[sharedUtils.redirect] to " + data);
    const { origin } = window.location;
    window.location.replace(origin + data);
};
exports.redirect = redirect;
function getCookie(cname) {
    const name = cname + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return false;
}
exports.getCookie = getCookie;
const serializeForm = (form) => {
    const formData = new FormData();
    for (const key in form) {
        if (key.match(/\[\d+\]/)) {
            const field = key.replace(/\[\d+\]/, "");
            formData.append(field, form[key]);
        }
        else if (form[key] === false || form[key] === null) {
            continue;
        }
        else {
            formData.append(key, form[key]);
        }
    }
    return formData;
};
exports.serializeForm = serializeForm;
const getCurrentPage = () => {
    const url = window.location.pathname.replace(/[\d]/g, "") + window.location.hash;
    const removeSlashDuplications = (string) => {
        if (string.includes("//")) {
            string.replace("//", "/");
            removeSlashDuplications(string);
        }
        else {
            // console.log(url)
            return url;
        }
    };
    return removeSlashDuplications(url);
};
exports.getCurrentPage = getCurrentPage;
const urlMatch = () => {
    const url = (0, exports.getCurrentPage)();
    const urlMatchElementHref = url.split("/").every((item) => window.location.href.includes(item));
    if (urlMatchElementHref) {
        return url;
    }
    else {
        return;
    }
    // console.log(urlMatchElementHref)
};
exports.urlMatch = urlMatch;
const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};
exports.capitalizeFirstLetter = capitalizeFirstLetter;
const linebreak2br = (content) => {
    let data = content;
    data = data.replace(/\r\n/g, "<br />");
    data = data.replace(/\n/g, "<br />");
    return data;
};
exports.linebreak2br = linebreak2br;
const getInitials = (firstname, lastname) => {
    const first = firstname.charAt(0) || "";
    const second = lastname.charAt(0) || "";
    return (0, exports.capitalizeFirstLetter)(first) + (0, exports.capitalizeFirstLetter)(second);
};
exports.getInitials = getInitials;
const matchProperties = (compareThis, compareWith, excludeParams) => {
    if (!compareThis || !compareWith)
        return;
    const propertiesArray = Object.keys(compareThis);
    for (let i = 0; i < propertiesArray.length; i++) {
        const key = propertiesArray[i];
        if (excludeParams.includes(key)) {
            continue;
        }
        if (compareThis[key] !== compareWith[key]) {
            return false;
        }
    }
    return true;
};
exports.matchProperties = matchProperties;
const getUTMs = (url) => {
    const _url = new URL(url);
    const params = new URLSearchParams(_url.search);
    const res = {};
    params.forEach((value, key) => {
        if (key.match(/^utm/i)) {
            res[key.toLowerCase()] = value;
        }
    });
    return res;
};
exports.getUTMs = getUTMs;
const getQueryParams = (url) => {
    const paramArr = url.slice(url.indexOf("?") + 1).split("&");
    const params = {};
    paramArr.map((param) => {
        const [key, val] = param.split("=");
        params[key] = decodeURIComponent(val);
    });
    return params;
};
exports.getQueryParams = getQueryParams;
const access = ({ app = "user", profile, section, permission, }) => {
    if (!profile || !section || !permission) {
        return false;
    }
    try {
        permissions_1.PERMISSIONS[app][profile.user_role][section][permission];
    }
    catch (error) {
        return false;
    }
    return permissions_1.PERMISSIONS[app][profile.user_role][section][permission];
};
exports.access = access;
const wrightToClipboard = (text) => {
    var _a;
    if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
        return (_a = navigator.clipboard) === null || _a === void 0 ? void 0 : _a.writeText(text);
    }
};
exports.wrightToClipboard = wrightToClipboard;
const testUrl = (text) => {
    const pattern = new RegExp(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    return pattern.test(text);
};
exports.testUrl = testUrl;
exports.dev_env_object = {
    API_BASE_URL: process.env.NODE_ENV === "production" ? "https://app.adopty.ru" : "https://local.adopty.ru",
    // Статика фронта
    STATIC_BASE_URL: process.env.NODE_ENV === "production" ? "https://app.adopty.ru" : "https://local.adopty.ru",
    // Контент от пользователя
    MEDIA_BASE_URL: process.env.NODE_ENV === "production" ? "https://app.adopty.ru" : "https://local.adopty.ru",
    WIDGET_BASE_URL: process.env.NODE_ENV === "production" ? "https://app.adopty.ru" : "https://local.adopty.ru",
};
const isDev = () => process.env.NODE_ENV === "development";
exports.isDev = isDev;
