"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Modal = void 0;
class Modal {
    constructor(parent, data) {
        console.log("[Modal.constructor]");
        this.data = data;
        this.bindedClose = this.closeEvent.bind(this);
        this.createElements();
        parent.appendChild(this.container);
    }
    closeEvent(e) {
        console.log("[Modal.closeEvent]");
        e.preventDefault();
        e.stopPropagation();
        if (this.data.onClose) {
            this.data.onClose();
        }
    }
    bindEvents() {
        if (this.closeButton)
            this.closeButton.addEventListener("click", this.bindedClose);
    }
    unbindEvents() {
        if (this.closeButton)
            this.closeButton.removeEventListener("click", this.bindedClose);
    }
    createContent() {
        console.log("[Modal.createContent]");
        this.tooltip_content = document.createElement("div");
        this.tooltip_content.classList.add("adopty-modal-content");
        this.tooltip_dialog.appendChild(this.tooltip_content);
        return this;
    }
    renderCloseButton() {
        const closeButton = document.createElement("div");
        closeButton.classList.add("adopty-modal-closebutton_outside");
        closeButton.innerHTML = `
			<svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M1.53333 1L16.4667 16" stroke="#ffffff" stroke-linecap="square"/>
				<path d="M16.4667 1L1.53333 16" stroke="#ffffff" stroke-linecap="square"/>
			</svg>
			<div>${this.data.closeBtn || "Close"}</div>
		`;
        this.closeButton = closeButton;
        this.tooltip_content.prepend(closeButton);
        return this;
    }
    createHeader() {
        console.log("[Modal.createHeader]");
        this.tooltip_header = document.createElement("div");
        this.tooltip_header.classList.add("adopty-modal-header");
        if (!this.data.header) {
            this.tooltip_header.classList.add("adopty-hide");
        }
        this.tooltip_header_title = document.createElement("h3");
        this.tooltip_header_title.classList.add("adopty-modal-title");
        this.tooltip_header_title.innerHTML = this.data.header;
        this.tooltip_header.appendChild(this.tooltip_header_title);
        this.tooltip_content.appendChild(this.tooltip_header);
        return this;
    }
    createBody() {
        console.log("[Modal.createBody]");
        this.tooltip_body = document.createElement("div");
        this.tooltip_body.classList.add("adopty-modal-body");
        this.tooltip_body.innerHTML = this.data.content;
        this.tooltip_content.appendChild(this.tooltip_body);
        return this;
    }
    createFooter() {
        console.log("[Modal.createFooter]");
        this.tooltip_footer = this.data.footer;
        this.tooltip_content.appendChild(this.tooltip_footer);
        return this;
    }
    createElements() {
        console.log("[Modal.createElements]");
        this.container = document.createElement("div");
        this.container.classList.add("adopty-custom", "adopty-modal");
        this.container.setAttribute("data-size", this.data.size || "default");
        if (this.data.modal_class)
            this.container.classList.add(this.data.modal_class);
        this.tooltip_dialog = document.createElement("div");
        this.tooltip_dialog.classList.add("adopty-modal-dialog");
        this.container.appendChild(this.tooltip_dialog);
        this.createContent().createHeader().createBody().createFooter();
        if (this.data.can_close) {
            this.renderCloseButton();
        }
        return this;
    }
    update(data) {
        console.log("[Modal.update]");
        this.data = Object.assign(Object.assign({}, this.data), data);
        if ("content" in data)
            this.tooltip_body.innerHTML = this.data.content;
        this.tooltip_header_title.innerHTML = this.data.header;
        this.container.setAttribute("data-size", this.data.size || "default");
        if (this.data.can_close && !this.closeButton) {
            this.renderCloseButton();
        }
        else if (!this.data.can_close && this.closeButton) {
            this.closeButton.remove();
            this.closeButton = null;
        }
        if (!this.data.header) {
            this.tooltip_header.classList.add("adopty-hide");
        }
        else {
            this.tooltip_header.classList.remove("adopty-hide");
        }
    }
    remove() {
        this.container.remove();
    }
}
exports.Modal = Modal;
