"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdoptyLink = void 0;
const HotspotsMain_1 = require("./HotspotsMain");
class AdoptyLink extends HotspotsMain_1.HotspotsMain {
    createElements() {
        console.log("[AdoptyLink.createElements]");
        this.triggerEl = document.createElement("span");
        this.triggerEl.setAttribute("data-aw", "aw-link");
        this.triggerEl.setAttribute("data-icon", this.item.icon);
        const linkTag = document.createElement("a");
        linkTag.setAttribute("target", "_blank");
        linkTag.setAttribute("href", this.item.url);
        linkTag.appendChild(this.triggerEl);
        this.a = linkTag;
        this.shadowRoot.appendChild(linkTag);
    }
    updateItem(target, item) {
        const oldItem = this.item;
        this.item = item;
        if (oldItem.element != item.element) {
            this.updateParent(target);
        }
        this.triggerEl.setAttribute("data-icon", this.item.icon);
        this.a.setAttribute("href", this.item.url);
    }
}
exports.AdoptyLink = AdoptyLink;
