"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdoptyOverlay = void 0;
class AdoptyOverlay {
    constructor() {
        this.setSVGAttributes = (element, attributes) => {
            this.overlay_settings = attributes;
            for (const key in attributes) {
                element.setAttribute(key, attributes[key]);
            }
            return this;
        };
        this.updateInnerMaskRectangle = (data) => {
            console.log("[AdoptyOverlay.updateInnerMaskRectangle]", data);
            this.setSVGAttributes(this.element_rect, Object.assign(Object.assign({}, data), { rx: "5" }));
        };
        console.log("[AdoptyOverlay.constructor]");
        this.visible = "false";
        this.overlay_settings = null;
    }
    showOverlay() {
        console.log("[AdoptyOverlay.showOverlay]");
        this.overlay.setAttribute("visible", "true");
    }
    hideOverlay() {
        console.log("[AdoptyOverlay.hideModal]");
        this.overlay.setAttribute("visible", "false");
    }
    createElementNSCustom(element) {
        return document.createElementNS("http://www.w3.org/2000/svg", element);
    }
    createSVGElement(element, attributes) {
        const svgElement = this.createElementNSCustom(element);
        this.setSVGAttributes(svgElement, attributes);
        return svgElement;
    }
    getOverlaySettings() {
        console.log("[AdoptyOverlay.getOverlaySettings]", this.overlay_settings);
        return this.overlay_settings;
    }
    createOverlay() {
        const sizeSettings = {
            width: "100vw",
            height: "100vh",
        };
        this.svg = this.createSVGElement("svg", Object.assign(Object.assign({}, sizeSettings), { class: "adopty-overlay" }));
        this.defs = this.createSVGElement("defs", {});
        this.mask = this.createSVGElement("mask", Object.assign(Object.assign({}, sizeSettings), { x: 0, y: 0, id: "mask" }));
        this.mask_inner_rect = this.createSVGElement("rect", Object.assign(Object.assign({}, sizeSettings), { fill: "#FFFFFF" }));
        this.element_rect = this.createSVGElement("rect", {});
        this.overlay_rect = this.createSVGElement("rect", Object.assign(Object.assign({}, sizeSettings), { fill: "rgba(0, 0, 0, 0.4)", mask: "url(#mask)", "fill-opacity": "1" }));
        this.mask.appendChild(this.mask_inner_rect);
        this.mask.appendChild(this.element_rect);
        this.defs.appendChild(this.mask);
        this.svg.appendChild(this.defs);
        this.svg.appendChild(this.overlay_rect);
        return this.svg;
    }
    appendOverlay(element) {
        console.log("[AdoptyOverlay.createoverlay]");
        const svg = this.createOverlay();
        this.overlay = document.createElement("div");
        this.overlay.appendChild(svg);
        this.overlay.setAttribute("visible", this.visible);
        return element.appendChild(this.overlay);
    }
    clearInnerMaskRectangle() {
        console.log("[AdoptyOverlay.clearInnerMaskRectangle]");
        const data = {
            width: "0",
            height: "0",
            x: "0",
            y: "0",
        };
        this.setSVGAttributes(this.element_rect, data);
    }
    connectedCallback() { }
    disconnectedCallback() { }
    static get observedAttributes() {
        return ["visible"];
    }
    attributeChangedCallback(name, oldValue, newValue) {
        var _a;
        if (!!oldValue && !!newValue && oldValue !== newValue) {
            if (name == "data-visible") {
                if (this.overlay === null)
                    return;
                (_a = this.overlay) === null || _a === void 0 ? void 0 : _a.setAttribute("visible", newValue);
            }
        }
    }
}
exports.AdoptyOverlay = AdoptyOverlay;
