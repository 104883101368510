"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Popover = void 0;
const Tooltip_1 = require("./Tooltip");
class Popover extends Tooltip_1.Tooltip {
    constructor(parent, triggerEl, data) {
        console.log("[Popover.constructor]");
        super(parent, triggerEl, data);
        this._tooltip_arrow_class = "";
        this.bindedClose = this.closeEvent.bind(this);
    }
    closeEvent(e) {
        console.log("[Popover.closeEvent]");
        e.preventDefault();
        e.stopPropagation();
        if (this.data.onClose) {
            this.data.onClose();
        }
    }
    bindEvents() {
        console.log("[Popover.bindEvents]");
        super.bindEvents();
        if (this.closeButton)
            this.closeButton.addEventListener("click", this.bindedClose);
    }
    unbindEvents() {
        super.unbindEvents();
        if (this.closeButton)
            this.closeButton.removeEventListener("click", this.bindedClose);
    }
    createBody() {
        console.log("[Popover.createBody]");
        this.tooltip_body = document.createElement("div");
        this.tooltip_body.classList.add("adopty-tooltip-body");
        this.container.appendChild(this.tooltip_body);
        return this;
    }
    clearContent(content) {
        let data = content;
        data = data.replace(/\r\n/g, "<br />");
        data = data.replace(/\n/g, "<br />");
        return data;
    }
    renderCloseButton() {
        const closeButton = document.createElement("span");
        closeButton.classList.add("adopty-modal-closebutton");
        closeButton.innerHTML = `
			<svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M1.53333 1L16.4667 16" stroke="#979797" stroke-linecap="square"/>
				<path d="M16.4667 1L1.53333 16" stroke="#979797" stroke-linecap="square"/>
			</svg>
		`;
        this.closeButton = closeButton;
        this.tooltip_header.append(closeButton);
        return this;
    }
    createHeader() {
        console.log("[Popover.createHeader]");
        this.tooltip_header = document.createElement("div");
        this.tooltip_header.classList.add("adopty-modal-header");
        if (!this.data.can_close && !this.data.header) {
            this.tooltip_header.classList.add("adopty-hide");
        }
        this.tooltip_header_title = document.createElement("h3");
        this.tooltip_header_title.classList.add("adopty-modal-title");
        this.tooltip_header_title.innerHTML = this.data.header;
        this.tooltip_header.appendChild(this.tooltip_header_title);
        if (this.data.can_close) {
            this.renderCloseButton();
        }
        this.tooltip_body.appendChild(this.tooltip_header);
        return this;
    }
    createContent() {
        console.log("[Popover.createContent]");
        this.tooltip_content = document.createElement("div");
        this.tooltip_content.classList.add("adopty-modal-body");
        this.tooltip_content.innerHTML = this.clearContent(this.data.content);
        this.tooltip_body.appendChild(this.tooltip_content);
        return this;
    }
    createFooter() {
        console.log("[Popover.createFooter]");
        if (this.data.footer) {
            this.tooltip_footer = this.data.footer;
            this.tooltip_body.appendChild(this.tooltip_footer);
        }
        return this;
    }
    createElements() {
        console.log("[Popover.createElements]");
        this.container = document.createElement("div");
        this.container.classList.add("adopty-custom", "adopty-tooltip");
        this.createArrow().createBody().createHeader().createContent().createFooter();
        return this;
    }
    update(data) {
        console.log("[Popover.update]");
        this.data = Object.assign(Object.assign({}, this.data), data);
        this.tooltip_content.innerHTML = this.clearContent(this.data.content);
        this.tooltip_header_title.innerHTML = this.data.header;
        if (!this.data.can_close && !this.data.header) {
            this.tooltip_header.classList.add("adopty-hide");
        }
        else {
            this.tooltip_header.classList.remove("adopty-hide");
        }
    }
}
exports.Popover = Popover;
