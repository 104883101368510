"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class EventHandler {
    constructor(onMessageHandlerMap) {
        this.register = () => {
            window.onmessage = this.onWindowMessage;
        };
        this.onWindowMessage = (event) => {
            console.log("[EventHandler.onWindowMessage]", event);
            const { data, type } = event.data;
            const handler = this._postMessageHandlerMap[type];
            if (handler) {
                handler(data);
                return;
            }
        };
        this._postMessageHandlerMap = onMessageHandlerMap;
    }
}
exports.default = EventHandler;
