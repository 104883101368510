"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ElementSelector = void 0;
const DOMPathBuilder_1 = require("src/projects/Widget/utils/DOMPathBuilder");
const ElementBorder_1 = require("src/projects/Widget/utils/ElementBorder");
class ElementSelector extends ElementBorder_1.ElementBorder {
    constructor(select, onSelectElement) {
        super(select, null);
        this.elementType = "hotspot";
        this.onSelectElement = onSelectElement;
        this.pathBuilder = new DOMPathBuilder_1.DOMPathBuilder();
        this.bindedEnterElement = this.EventEnterElement.bind(this);
        this.bindedSelectElement = this.EventSelectElement.bind(this);
    }
    skipElementSelection(element) {
        if (!element ||
            element.tagName == "HTML" ||
            element.tagName == "IFRAME" ||
            !element.hasAttribute ||
            element.hasAttribute("data-aw") ||
            (element.class && !element.class.includes("adopty-selection"))) {
            return true;
        }
    }
    EventEnterElement(e) {
        var _a, _b;
        console.log("[ElementSelector.EventEnterElement]");
        if (!e)
            return;
        e.preventDefault();
        e.stopImmediatePropagation();
        e.stopPropagation();
        const element = e.target || null;
        if (this.skipElementSelection(element))
            return;
        const path = this.pathBuilder.find(element);
        if (!path)
            return;
        const elementPointerEvents = (_b = (_a = element === null || element === void 0 ? void 0 : element.style) === null || _a === void 0 ? void 0 : _a.css) === null || _b === void 0 ? void 0 : _b.pointerEvents;
        if (elementPointerEvents) {
            element.style.css.pointerEvents = "none";
        }
        const lastIndexOfDelim = path.lastIndexOf(">") + 1;
        const elementRect = element.getBoundingClientRect();
        this.setHelpBlocksData({
            offset_object: elementRect,
            path: path,
            delimeter: lastIndexOfDelim,
        });
        // [Q] ?? Пока не понял зачем это
        // this.adopty_item = this.parent.hotspots.find(
        // 	(item) => item.id == target.getAttribute("[data-adopty]"),
        // );
        // const parent = this.$(`[data-adopty="${this.current_id}"]`) as AdoptyLink | AdoptyTooltip;
        // if (parent) {
        // 	parent.getParentInstance().updateParent(parent);
        // }
        if (elementPointerEvents) {
            element.style.css.pointerEvents = elementPointerEvents;
        }
    }
    EventSelectElement(e) {
        console.log("[ElementSelector.EventSelectElement]");
        const element = e.target;
        if (this.skipElementSelection(element))
            return;
        e.preventDefault();
        e.stopImmediatePropagation();
        e.stopPropagation();
        this.onSelectElement({
            element: element,
            path: this.pathBuilder.find(element),
            type: this.elementType,
        });
    }
    start_selecting(item, type) {
        this.elementType = type;
        if (this.$("adopty-modal")) {
            this.$("adopty-modal").style.display = "none";
        }
        document.addEventListener("mouseenter", this.bindedEnterElement, true);
        document.addEventListener("click", this.bindedSelectElement, true);
        if (item === null || item === void 0 ? void 0 : item.element)
            this.show_selection(item);
    }
    stop_selecting(hide_selection) {
        if (this.$("adopty-modal")) {
            this.$("adopty-modal").removeAttribute("style");
        }
        document.removeEventListener("mouseenter", this.bindedEnterElement, true);
        document.removeEventListener("click", this.bindedSelectElement, true);
        if (hide_selection)
            this.hide_selection();
    }
}
exports.ElementSelector = ElementSelector;
