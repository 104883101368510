"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HotspotsMain = void 0;
const hotspotStyles_1 = require("../../styles/hotspotStyles");
const elementPosition_1 = require("src/projects/Widget/utils/elementPosition");
const CustomElement_1 = require("src/projects/Widget/shadowDOM/CustomElement");
class HotspotsMain extends CustomElement_1.CustomElement {
    constructor(parent, item) {
        console.log("[HotspotsMain.constructor]");
        super();
        this.bindChangePositionEvent = () => {
            var _a, _b;
            console.log("[HotspotsMain.bindChangePositionEvent] ID", (_a = this.item) === null || _a === void 0 ? void 0 : _a.id);
            const eventListenerBlock = ((_b = this.parent_scroll) === null || _b === void 0 ? void 0 : _b.scrollParent) || window;
            eventListenerBlock.addEventListener("scroll", this.bindedChangePositionEvent, true);
            window.addEventListener("resize", this.bindedChangePositionEvent, true);
        };
        this.parent = parent;
        this.parent_scroll = (0, elementPosition_1.getParentScroll)(parent);
        this.item = item;
        this.interval = null;
        this.start_time = Date.now();
        this.styles = hotspotStyles_1.HOTSPOTS_MAIN_STYLES;
        this.bindedChangePositionEvent = this.changePositionEvent.bind(this);
    }
    startTimer() {
        this.interval = setInterval(() => {
            if (Date.now() - this.start_time < 900) {
                this.changePositionEvent();
            }
            else {
                clearInterval(this.interval);
                this.interval = null;
            }
        }, 300);
    }
    connectedCallback() {
        this.setAttribute("data-adopty", this.item.id.toString());
        super.connectedCallback();
        this.setPosition();
        this.bindChangePositionEvent();
        this.startTimer();
    }
    unbindChangePositionEvent() {
        var _a;
        console.log("[HotspotsMain.unbindChangePositionEvent]");
        const eventListenerBlock = ((_a = this.parent_scroll) === null || _a === void 0 ? void 0 : _a.scrollParent) || window;
        eventListenerBlock.removeEventListener("scroll", this.bindedChangePositionEvent, true);
        window.removeEventListener("resize", this.bindedChangePositionEvent, true);
    }
    setPosition() {
        var _a;
        console.log("[HotspotsMain.setPosition] ID ", (_a = this.item) === null || _a === void 0 ? void 0 : _a.id);
        const scrollTop = document.documentElement.scrollTop;
        const scrollLeft = document.documentElement.scrollLeft;
        const coords = this.parent.getBoundingClientRect();
        const zIndexModal = (0, elementPosition_1.getParentZIndex)(this.parent);
        this.style.cssText = `
			z-index: ${zIndexModal};
			top: ${coords.y + scrollTop}px;
			left: ${coords.right + scrollLeft}px;
			height: ${coords.height}px;
		`;
    }
    changePositionEvent() {
        console.log("[HotspotsMain.changePositionEvent]");
        const parentNode = this.parent;
        const coords = parentNode.getBoundingClientRect();
        const zIndexModal = (0, elementPosition_1.getParentZIndex)(parentNode);
        this.style.zIndex = zIndexModal;
        this.style.top = coords.top + window.scrollY + "px";
        this.style.left = coords.right + window.scrollX + "px";
    }
    updateParent(parent) {
        this.parent = parent;
        this.parent_scroll = (0, elementPosition_1.getParentScroll)(parent);
        this.changePositionEvent();
    }
    removeElement() {
        this.unbindChangePositionEvent();
        clearInterval(this.interval);
        this.interval = null;
        this.remove();
    }
}
exports.HotspotsMain = HotspotsMain;
