"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MutationObserverWrapper = void 0;
const sharedUtils_1 = require("src/utils/sharedUtils");
const elementPosition_1 = require("src/projects/Widget/utils/elementPosition");
const DOMSelectorCleaner_1 = require("src/projects/Widget/utils/DOMSelectorCleaner");
const AdoptyLink_1 = require("src/projects/Widget/shadowDOM/hotspots/AdoptyLink");
const AdoptyTooltip_1 = require("src/projects/Widget/shadowDOM/hotspots/AdoptyTooltip");
class MutationObserverWrapper extends DOMSelectorCleaner_1.DOMSelectorCleaner {
    constructor(parent, events) {
        super();
        this.mutationCallback = (mutationList) => {
            for (const mutation of mutationList) {
                if (mutation.type === "childList" || mutation.type === "attributes") {
                    this.checkElements();
                }
            }
        };
        this.startObserving = () => {
            if (!this.observer) {
                this.observer = new MutationObserver(this.mutationCallback);
            }
            this.observer.observe(this.observedNode, this.config);
            this.checkElements();
        };
        this.parent = parent;
        this.observedNode = document.querySelector("body");
        this.config = { childList: true, attributes: true, subtree: true };
        this.observer = null;
    }
    getDataFromItem(item) {
        const { id } = item;
        const data = this.isConstructorActive() ? item.draft : item;
        return Object.assign(Object.assign({}, data), { id });
    }
    // Пока не используется
    // bindChangePositionEvent(hotspot) {
    // 	const currentElement = this.$(`[data-adopty="${hotspot.id}"]`) as AdoptyLink | AdoptyTooltip;
    // 	if (currentElement) {
    // 		console.log("[bindChangePositionEvent] START");
    // 		// currentElement.getParentInstance().bindChangePositionEvent(hotspot);
    // 	} else {
    // 		console.log("[bindChangePositionEvent] NO");
    // 	}
    // }
    // Пока не используется
    // preserveElements = (mutation) => {
    // 	// console.log(`[MutationObserverWrapper.preserveElements] called`);
    // 	if (mutation.removedNodes && mutation.removedNodes.length > 0) {
    // 		Array.from(mutation.removedNodes).map((node: Element) => {
    // 			if (!!node && node.attributes && node.attributes["data-icon"] && !node.attributes["data-changed"]) {
    // 				// console.log(`[MutationObserverWrapper.preserveElements] - preserved element: ${node}`);
    // 				// mutation.target.appendChild(node);
    // 			}
    // 		});
    // 	}
    // };
    // Пока не используется
    // removeElement = () => {
    // 	// console.log("[MutationObserverWrapper.removeElement] called");
    // 	this.selectors.length > 0 &&
    // 		this.selectors.map((item) => {
    // 			if (item.on_page !== window.location.href) {
    // 				const child = wParent.document.querySelector(item.element);
    // 				wParent.document.removeChild(child);
    // 			}
    // 		});
    // };
    isConstructorActive() {
        return this.parent.isAdmin && this.parent.GatewayConstructor() && this.parent.GatewayConstructor().visible
            ? true
            : false;
    }
    isVisibleItem(item) {
        if (this.isConstructorActive() || item.status == "published") {
            return true;
        }
        return false;
    }
    clearElements() {
        this.parent.hotspots.forEach((item) => {
            const element = this.$(`[data-adopty="${item.id}"]`);
            if (element) {
                this.removeElement(element);
            }
        });
    }
    checkElements() {
        this.parent.hotspots.forEach((item) => {
            this.checkElement(item);
        });
    }
    checkElement(item) {
        const newItem = this.getDataFromItem(item);
        const target = this.$(newItem.element);
        const element = this.$(`[data-adopty="${newItem.id}"]`);
        if (!this.isVisibleItem(newItem) || (!target && !element)) {
            return;
        }
        if (!!target && !element) {
            this.appendElement(target, newItem);
        }
        else if (!target && !!element) {
            this.removeElement(element);
        }
        else if (element.item.type != newItem.type) {
            this.removeElement(element);
            this.appendElement(target, newItem);
        }
    }
    getElementStatus(element, onPage) {
        if (onPage != (0, sharedUtils_1.getCurrentPage)())
            return "pagewrong";
        if (!element || !this.$(element))
            return "notfound";
        const notUniq = document.querySelectorAll(element).length > 1;
        if (notUniq)
            return "notuniq";
        return "ok";
    }
    updateElement(item) {
        this.checkElement(item);
        const newItem = this.getDataFromItem(item);
        const target = this.$(newItem.element);
        const element = this.$(`[data-adopty="${newItem.id}"]`);
        if (this.isVisibleItem(newItem) && target && element) {
            element.updateItem(target, newItem);
        }
    }
    appendElement(target, item) {
        console.log(`[MutationObserverWrapper.appendElement] type ${item.type} id ${item.id}`);
        if (!(0, elementPosition_1.hasParentVisibility)(target) || item.on_page != (0, sharedUtils_1.getCurrentPage)()) {
            return;
        }
        if (item.type == "url") {
            this.$("body").appendChild(new AdoptyLink_1.AdoptyLink(target, item));
        }
        else if (item.type == "tooltip") {
            this.$("body").appendChild(new AdoptyTooltip_1.AdoptyTooltip(target, item));
        }
    }
    removeElement(element) {
        console.log("[MutationObserverWrapper.removeElement] ID", element.item.id);
        element.removeElement();
    }
}
exports.MutationObserverWrapper = MutationObserverWrapper;
