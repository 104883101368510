"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ElementBorder = void 0;
const elementPosition_1 = require("./elementPosition");
class ElementBorder {
    get adopty_item() {
        return this._adopty_item;
    }
    set adopty_item(data) {
        this._adopty_item = data;
    }
    constructor(select, adopty_item) {
        this.show_selection = (adopty_item) => {
            var _a;
            console.log("[ElementBorder.show_selection]");
            this._adopty_item = adopty_item;
            const { element } = this._adopty_item;
            const DomElement = this.$(element);
            if (!DomElement) {
                this.hide_selection();
                return;
            }
            ;
            const scroll_parent = (_a = (0, elementPosition_1.getParentScroll)(DomElement)) === null || _a === void 0 ? void 0 : _a.scrollParent;
            if (scroll_parent) {
                scroll_parent.addEventListener("scroll", this.bindedScrollEvent);
            }
            this.updateSelectorPosition(this._adopty_item, element);
            const elementRect = DomElement.getBoundingClientRect();
            const bodyRect = document.body.getBoundingClientRect();
            const screenHeight = this.$("html").clientHeight;
            let topScroll = (screenHeight - elementRect.height) / 2;
            if (topScroll < 0)
                topScroll = 0;
            window.scrollTo({
                top: elementRect.top - bodyRect.top - topScroll,
                left: elementRect.left,
                behavior: "smooth",
            });
            return element;
        };
        this.hide_selection = () => {
            console.log("[ElementBorder.hide_selection]");
            this._selection_border.style.visibility = "hidden";
            this._selection_path.style.visibility = "hidden";
        };
        this.$ = select;
        this._selection_border = null;
        this._selection_path = null;
        this._adopty_item = adopty_item || null;
        this.bindedScrollEvent = this.scrollEvent.bind(this);
        this.createSelectionElement();
    }
    createSelectionElement() {
        console.log("[ElementBorder.createSelectionElement]");
        this._selection_border = document.createElement("span");
        this._selection_path = document.createElement("span");
        this._selection_border.className = "adopty-selection_border";
        this._selection_path.className = "adopty-selection_element";
        this._selection_border.style.visibility = "hidden";
        this._selection_path.style.visibility = "hidden";
        this._selection_border.style.cssText = "opacity: 0";
        this._selection_path.style.cssText = "opacity: 0";
        this.$("body").appendChild(this._selection_border);
        this.$("body").appendChild(this._selection_path);
    }
    scrollEvent() {
        console.log("[ElementBorder.scrollEvent]");
        if (!this._adopty_item)
            return;
        const { element } = this._adopty_item;
        const selector = this.$(element);
        const target_offset = selector.getBoundingClientRect();
        this._selection_border.style.top = `${target_offset.y + window.scrollY}px`;
        this._selection_border.style.left = `${target_offset.x}px`;
        this._selection_path.style.cssText = `
			top: ${target_offset.y - 21 + window.scrollY}px;
			left: ${target_offset.x + 8}px;
		`;
    }
    setHelpBlocksData({ offset_object, path, delimeter, }) {
        console.log("[ElementBorder.setHelpBlocksData]");
        this._selection_border.style.cssText = `
			top: ${offset_object.y + window.scrollY}px;
			left: ${offset_object.x}px;
			width: ${offset_object.width}px;
			height: ${offset_object.height}px;
			visibility: visible;
		`;
        this._selection_path.style.cssText = `
			top: ${offset_object.y - 21 + window.scrollY}px;
			left: ${offset_object.x + 8}px;
			visibility: visible;
		`;
        this._selection_path.textContent = path.slice(delimeter);
    }
    updateSelectorPosition(selected_item, element) {
        console.log("[ElementBorder.updateSelectorPosition]");
        if (!this.$(element)) {
            return;
        }
        const target_offset = this.$(element).getBoundingClientRect();
        const lastIndexOfDelim = element.lastIndexOf(">") + 1;
        this.setHelpBlocksData({
            offset_object: target_offset,
            path: element,
            delimeter: lastIndexOfDelim,
        });
    }
}
exports.ElementBorder = ElementBorder;
