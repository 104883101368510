"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Tooltip = void 0;
class Tooltip {
    constructor(parent, triggerEl, data) {
        console.log("[Tooltip.constructor]");
        this.triggerEl = triggerEl;
        this.data = data;
        this._tooltip_arrow_class = "left";
        this.bindedClick = this.clickEvent.bind(this);
        this.bindedMouseover = this.mouseOverEvent.bind(this);
        this.bindedMouseout = this.mouseOutEvent.bind(this);
        this.createElements();
        parent.appendChild(this.container);
    }
    clearTooltipTimer() {
        console.log("[Tooltip.clearTooltipTimer]");
        this._visible = true;
        clearTimeout(this.tooltip_timer);
        this.tooltip_timer = null;
    }
    setTooltipTimer(cb) {
        console.log("[Tooltip.setTooltipTimer]");
        this.tooltip_timer = setTimeout(() => {
            this._visible = false;
            cb();
        }, 100);
    }
    createArrow() {
        console.log("[Tooltip.createArrow]");
        this._tooltip_arrow = document.createElement("div");
        this._tooltip_arrow.classList.add("adopty-tooltip-arrow");
        this._tooltip_arrow.className = "aw-info-arrow " + this._tooltip_arrow_class;
        this.container.appendChild(this._tooltip_arrow);
        return this;
    }
    createBody() {
        console.log("[Tooltip.createBody]");
        this.tooltip_body = document.createElement("div");
        this.tooltip_body.classList.add("aw-info-body");
        this.container.appendChild(this.tooltip_body);
        return this;
    }
    updateTooltipData(data = this.data) {
        console.log("[Tooltip.updateTooltipData]");
        this.data = data;
        this.tooltip_body.innerHTML = this.data.content;
        this.unbindEvents();
        this.bindEvents();
        return this;
    }
    createElements() {
        console.log("[Tooltip.createElements]");
        this.container = document.createElement("div");
        this.container.classList.add("aw-info");
        this.createArrow().createBody().updateTooltipData();
        return this;
    }
    unbindEvents() {
        console.log("[Tooltip.unbindEvents]");
        clearTimeout(this.tooltip_timer);
        this.tooltip_timer = null;
        this.triggerEl.removeEventListener("click", this.bindedClick);
        this.triggerEl.removeEventListener("mouseover", this.bindedMouseover);
        this.triggerEl.removeEventListener("mouseout", this.bindedMouseout);
    }
    bindEvents() {
        console.log("[Tooltip.bindEvents]");
        this.hide();
        if (this.data.show_on == "always") {
            this.show();
        }
        else if (this.data.show_on == "click") {
            this.triggerEl.addEventListener("click", this.bindedClick);
        }
        else if (this.data.show_on == "mouseover") {
            this.triggerEl.addEventListener("mouseover", this.bindedMouseover);
            this.triggerEl.addEventListener("mouseout", this.bindedMouseout);
        }
    }
    clickEvent(e) {
        console.log("[Tooltip.clickEvent]");
        e.preventDefault();
        e.stopPropagation();
        this._visible = !this._visible;
        const target = e.target;
        if (target.hasAttribute("data-aw")) {
            if (this._visible) {
                this.show();
            }
            else {
                this.hide();
            }
        }
    }
    mouseOverEvent(e) {
        console.log("[Tooltip.mouseOverEvent]");
        this.clearTooltipTimer();
        this.show();
    }
    mouseOutEvent(e) {
        console.log("[Tooltip.mouseOutEvent]");
        this.setTooltipTimer(() => this.hide());
    }
    show() {
        this.container.classList.add("aw-popup-visible");
    }
    hide() {
        this.container.classList.remove("aw-popup-visible");
    }
}
exports.Tooltip = Tooltip;
