"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOMPathBuilder = void 0;
const DOMSelectorCleaner_1 = require("./DOMSelectorCleaner");
class DOMPathBuilder {
    constructor() {
        this.selectorCleaner = new DOMSelectorCleaner_1.DOMSelectorCleaner();
    }
    parentElements(element) {
        const parents = [];
        while (element) {
            const tagName = element.nodeName.toLowerCase();
            const cssId = element.id ? `[id='${element.id}']` : "";
            let cssClass = "";
            if (element.className && element.className.trim().length && typeof element.className === "string") {
                // escape class names
                cssClass = ".".concat(element.className
                    .trim()
                    .replace(/\s+/g, ".")
                    .replace(/[:*+?^${}()|[\]\\]/gi, "\\$&"));
            }
            parents.unshift({
                element: element,
                selector: tagName + cssId + cssClass,
            });
            element = element.parentNode !== document ? element.parentNode : false;
        }
        return parents;
    }
    nthElement(element) {
        const sameType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
        let c = element;
        let nth = 1;
        while (c.previousElementSibling !== null) {
            if (!sameType || c.previousElementSibling.nodeName === element.nodeName) {
                nth++;
            }
            c = c.previousElementSibling;
        }
        return nth.toString();
    }
    nthSelectorNeeded(selector, path) {
        const querySelector = path === "" || path === " " ? selector.trim() : "".concat(path, " > ").concat(selector).trim();
        return document.querySelectorAll(this.selectorCleaner.cleanSelector(querySelector)).length > 1;
    }
    buildPathString(parents) {
        const pathArr = [];
        parents.forEach((parent) => {
            if (this.nthSelectorNeeded(parent.selector, pathArr.join(" > "))) {
                parent.selector += ":nth-of-type(".concat(this.nthElement(parent.element), ")");
            }
            pathArr.push(parent.selector);
        });
        return pathArr.join(" > ");
    }
    find(element) {
        if (!(element instanceof HTMLElement) || element.tagName == "IFRAME" || element.getAttribute("data-aw")) {
            return;
        }
        return this.buildPathString(this.parentElements(element));
    }
}
exports.DOMPathBuilder = DOMPathBuilder;
