"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdoptyPopover = void 0;
const Popover_1 = require("src/projects/Widget/mainDOM/Popover");
const TourStepComponentBase_1 = require("src/projects/Widget/shadowDOM/tours/TourStepComponentBase");
class AdoptyPopover extends TourStepComponentBase_1.TourStepComponentBase {
    constructor(parent, data, settings) {
        console.log("[AdoptyPopover.constructor]");
        super(data, settings);
        this.ARROW_SIZE = 10; // Размер стрелки
        this.SCREEN_OFFSET = 5; // Отступ от края экрана
        this.OVERLAY_PADDING = 5; // Отступ вокруг элемент для overlay
        this.parent = parent;
        this.bindedChangePositionEvent = this.updateOverlay.bind(this);
        this.bindedParentClick = this.onClickParent.bind(this);
    }
    onClickParent(e) {
        console.log("[AdoptyPopover.onClickParent]");
        e.preventDefault();
        e.stopPropagation();
        this.parent.click();
        if (this.settings.onNext)
            this.settings.onNext();
    }
    createPopover() {
        console.log("[AdoptyPopover.createPopover]");
        this.element = new Popover_1.Popover(this.shadowRoot, this.parent, {
            header: this.data.data.title,
            content: this.data.data.content,
            footer: this.footer,
            show_on: "always",
            can_close: this.settings.isFinish || this.data.data.can_close,
            onClose: this.settings.demoMode ? () => { } : this.settings.onClose,
        });
        this.container_element = this.element.container;
        return this;
    }
    createClickable() {
        console.log("[AdoptyPopover.createClickable]");
        this.clickable = document.createElement("div");
        this.clickable.classList.add("adopty-overlay-clickable");
    }
    bindEvents() {
        console.log("[AdoptyPopover.bindEvents]");
        document.addEventListener("scroll", this.bindedChangePositionEvent, true);
        window.addEventListener("resize", this.bindedChangePositionEvent, true);
        screen.orientation.addEventListener("change", this.bindedChangePositionEvent, true);
        if (this.clickable)
            this.clickable.addEventListener("click", this.bindedParentClick, true);
        super.bindEvents();
    }
    unbindEvents() {
        console.log("[AdoptyPopover.unbindEvents]");
        document.removeEventListener("scroll", this.bindedChangePositionEvent, true);
        window.removeEventListener("resize", this.bindedChangePositionEvent, true);
        screen.orientation.removeEventListener("change", this.bindedChangePositionEvent, true);
        if (this.clickable)
            this.clickable.removeEventListener("click", this.bindedParentClick, true);
        super.unbindEvents();
    }
    updateItem(data) {
        console.log("[AdoptyPopover.updateItem]");
        this.data = data;
    }
    scrollToBlock() {
        console.log("[AdoptyPopover.scrollToBlock]");
        const elementRect = this.parent.getBoundingClientRect();
        const bodyRect = document.body.getBoundingClientRect();
        const popoverRect = this.container_element.getBoundingClientRect();
        const screenHeight = this.$("html").clientHeight;
        let topScroll = (screenHeight - (popoverRect.height + 20 + elementRect.height)) / 2;
        if (topScroll < 0)
            topScroll = 0;
        window.scrollTo({
            top: elementRect.top - bodyRect.top - topScroll,
            left: elementRect.left,
            behavior: "smooth",
        });
    }
    updateOverlay() {
        console.log("[AdoptyPopover.updateOverlay]");
        if (this.overlayClass) {
            const elementRect = this.parent.getBoundingClientRect();
            const spreadData = elementRect.toJSON();
            const coords = spreadData
                ? Object.assign(Object.assign({}, spreadData), { width: spreadData.width + this.OVERLAY_PADDING * 2, height: spreadData.height + this.OVERLAY_PADDING * 2, x: spreadData.x - this.OVERLAY_PADDING, y: spreadData.y - this.OVERLAY_PADDING }) : null;
            this.overlayClass.updateInnerMaskRectangle(Object.assign({}, coords));
            if (this.clickable) {
                const clickableCSS = `
					width: ${coords.width}px;
					height: ${coords.height}px;
					top: ${coords.y}px;
					left: ${coords.x}px;
				`;
                this.clickable.style.cssText = clickableCSS;
            }
        }
        this.setPosition();
    }
    setPosition() {
        console.log("[AdoptyPopover.setPosition]");
        const [screenWidth, screenHeight] = [
            this.$("body").clientWidth,
            this.$("html").clientHeight,
            this.$("html").clientLeft,
        ];
        const overlayRect = this.overlayClass.getOverlaySettings();
        const modalRect = this.container_element.getBoundingClientRect();
        const contentHeight = this.ARROW_SIZE + modalRect.height;
        const topHeight = overlayRect.y;
        const bottomHeight = screenHeight - overlayRect.y - overlayRect.height;
        const canRenderTop = contentHeight < topHeight;
        const canRenderBottom = contentHeight < bottomHeight;
        let calculatedOffsetX = overlayRect.x + overlayRect.width / 2 - modalRect.width / 2;
        let calculatedOffsetY = overlayRect.y + overlayRect.height / 2 - modalRect.height / 2;
        const renderTooltipOnTop = () => {
            calculatedOffsetY = overlayRect.top - modalRect.height - this.OVERLAY_PADDING - this.ARROW_SIZE;
            arrow.style.left = overlayRect.left + overlayRect.width / 2 - calculatedOffsetX - 5 + "px";
            arrow.style.rotate = "270deg";
            arrow.style.bottom = "0px";
            arrow.style.top = null;
        };
        const renderTooltipOnBottom = () => {
            calculatedOffsetY = overlayRect.y + overlayRect.height + this.ARROW_SIZE;
            arrow.style.bottom = null;
            arrow.style.top = null;
        };
        const renderY = () => {
            if (canRenderBottom || bottomHeight > topHeight) {
                return renderTooltipOnBottom();
            }
            return renderTooltipOnTop();
        };
        const renderTooltipOnRight = () => {
            calculatedOffsetX = overlayRect.right + this.OVERLAY_PADDING + this.ARROW_SIZE;
            arrow.style.left = -1 + "px";
            arrow.style.rotate = "0deg";
            arrow.style.top = "50%";
            arrow.style.bottom = "50%";
        };
        const renderTooltipOnLeft = () => {
            calculatedOffsetX = overlayRect.left - this.OVERLAY_PADDING - this.ARROW_SIZE - modalRect.width;
            arrow.style.left = modalRect.width - 1 + "px";
            arrow.style.rotate = "180deg";
            arrow.style.top = "50%";
            arrow.style.bottom = "50%";
        };
        const renderX = () => {
            if (this.ARROW_SIZE + modalRect.width < overlayRect.x) {
                return renderTooltipOnLeft();
            }
            return renderTooltipOnRight();
        };
        const chooseTooltipSide = () => {
            if (!canRenderBottom && !canRenderTop) {
                return renderX();
            }
            return renderY();
        };
        const exceedWidth = (modalRect.width - overlayRect.width) / 2;
        if (overlayRect.left < exceedWidth) {
            // Если не хватает места слева для расположения по середине элемента, то приживаем к левому краю
            calculatedOffsetX = this.SCREEN_OFFSET;
        }
        else if (screenWidth - overlayRect.right < exceedWidth) {
            // Если не хватает места справа для расположения по середине элемента, то приживаем к правому краю
            calculatedOffsetX = screenWidth - modalRect.width - this.SCREEN_OFFSET;
        }
        const arrow = this.container_element.querySelector(".aw-info-arrow");
        arrow.style.left = overlayRect.left + overlayRect.width / 2 - calculatedOffsetX - 5 + "px";
        arrow.style.rotate = "90deg";
        arrow.style.bottom = null;
        chooseTooltipSide();
        this.container_element.style.top = String(calculatedOffsetY) + "px";
        this.container_element.style.left = String(calculatedOffsetX) + "px";
        return this;
    }
    createElements() {
        console.log("[AdoptyPopover.createElements]");
        if (this.data.data.next_onevent == "click_element") {
            this.createClickable();
            this.shadowRoot.appendChild(this.clickable);
        }
        else {
            this.createFooter();
        }
        this.createPopover();
        this.shadowRoot.appendChild(this.container_element);
        if (this.overlayClass) {
            this.shadowRoot.appendChild(this.overlayClass.createOverlay());
        }
        this.updateOverlay();
        this.bindEvents();
        this.scrollToBlock();
    }
}
exports.AdoptyPopover = AdoptyPopover;
