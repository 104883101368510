"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getParentScroll = exports.getParentZIndex = exports.hasParentVisibility = exports.elementPositionUpdater = void 0;
const elementPositionUpdater = (parent, id, element = null) => {
    let parentY = null;
    let parentX = null;
    const scrollTop = document.documentElement.scrollTop;
    let interval = setInterval(() => positionUpdate(), 30);
    const positionUpdate = () => {
        element = element || document.querySelector(`[data-adopty="${id}"]`);
        const parentCoordinates = parent.getBoundingClientRect();
        let wasUpdated = false;
        if (parentCoordinates.y != parentY || parentCoordinates.x != parentX) {
            parentY = parentCoordinates.y + scrollTop;
            parentX = parentCoordinates.x;
            wasUpdated = true;
        }
        else {
            clearInterval(interval);
            interval = null;
            element.style.top = `${parentCoordinates.y + scrollTop}px`;
            element.style.left = `${parentCoordinates.right}px`;
            if (wasUpdated) {
                element.style.zIndex = "999999";
            }
        }
    };
};
exports.elementPositionUpdater = elementPositionUpdater;
function hasParentVisibility(node) {
    let el = node;
    if (!(el instanceof HTMLElement || el instanceof ShadowRoot)) {
        return null;
    }
    if (el instanceof ShadowRoot) {
        el = el.host;
    }
    const style = window.getComputedStyle(el);
    if (style.visibility == "hidden" || style.display == "none" || style.opacity == "0") {
        return false;
    }
    if (el.parentNode && el.parentNode.tagName !== "HTML") {
        return hasParentVisibility(el.parentNode);
    }
    return true;
}
exports.hasParentVisibility = hasParentVisibility;
function getParentZIndex(node) {
    let el = node;
    if (!(el instanceof HTMLElement || el instanceof ShadowRoot)) {
        return null;
    }
    if (el instanceof ShadowRoot) {
        el = el.host;
    }
    const zIndex = window.getComputedStyle(el)["zIndex"];
    if (Number(zIndex)) {
        return String(zIndex + 1);
    }
    else {
        return getParentZIndex(el.parentNode);
    }
}
exports.getParentZIndex = getParentZIndex;
function getParentScroll(node) {
    let el = node;
    if (!(el instanceof HTMLElement || el instanceof ShadowRoot)) {
        return null;
    }
    if (el instanceof ShadowRoot) {
        el = el.host;
    }
    const style = window.getComputedStyle(el);
    const overflowY = style.overflowY;
    const overflowX = style.overflowX;
    const scrollSizeY = el.scrollHeight;
    const scrollSizeX = el.scrollWidth;
    const clientSizeY = el.clientHeight;
    const clientSizeX = el.clientWidth;
    const isScrolledY = scrollSizeY > clientSizeY;
    const isScrolledX = scrollSizeX > clientSizeX;
    if (((isScrolledY && !overflowY.includes("visible") && !overflowY.includes("hidden")) ||
        (isScrolledX && !overflowX.includes("visible") && !overflowX.includes("hidden"))) &&
        style.display !== "none") {
        return {
            scrollParent: el,
        };
    }
    return getParentScroll(el.parentNode) || { scrollParent: document.body };
}
exports.getParentScroll = getParentScroll;
