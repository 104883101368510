"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const sharedUtils_1 = require("src/utils/sharedUtils");
const render_quote = (data) => {
    return `<blockquote><p style="text-align: ${data.alignment || "left"}">${(0, sharedUtils_1.linebreak2br)(data.text)}</p></blockquote>`;
};
const render_paragraph = (data) => {
    return `<p style="text-align: ${data.alignment || "left"}">${data.text}</p>`;
};
const render_header = (data) => {
    return `<h${data.level} style="text-align: ${data.alignment || "left"}">${data.text}</h${data.level}>`;
};
const render_code = (data) => {
    return `<pre>${data.code}</pre>`;
};
const render_image = (data) => {
    var _a, _b;
    if ((_a = data.file) === null || _a === void 0 ? void 0 : _a.url) {
        return `<div class="adopty-custom-image-wrapper ${data.stretched ? "adopty-custom-image--stretched" : ""} ${data.withBorder ? "adopty-custom-image--withBorder" : ""} ${data.withBackground ? "adopty-custom-image--withBackground" : ""}"><div class="adopty-custom-image"><img src="${sharedUtils_1.dev_env_object.MEDIA_BASE_URL}${(_b = data.file) === null || _b === void 0 ? void 0 : _b.url}"></div>${data.caption ? `<h4 class="adopty-custom-caption">${data.caption}</h4>` : ""}</div>`;
    }
    return "";
};
const render_delimiter = (data) => {
    return "<hr/>";
};
const render_embed = (data) => {
    if (data.service == "youtube") {
        return `<div class="adopty-embed-wrapper"><div></div><iframe width="560" height="315" frameborder='0' allowfullscreen src='${data.embed}'></iframe></div>`;
    }
    return "";
};
const render_list = (data) => {
    return ("<ul>" +
        data.items
            .map((item) => {
            return `<li style='list-style: ${data.style == "ordered" ? "decimal" : "disc"}'>${item}</li>`;
        })
            .join("") +
        "</ul>");
};
const render_layout = (data) => {
    return ("<div class='adopty-custom-layout'>" +
        data.cols
            .map((item) => {
            return `<div class="adopty-custom-layout__col">${Editor2Html(item)}</div>`;
        })
            .join("") +
        "</div>");
};
const type_map = {
    paragraph: render_paragraph,
    header: render_header,
    delimiter: render_delimiter,
    list: render_list,
    quote: render_quote,
    embed: render_embed,
    code: render_code,
    image: render_image,
    layout: render_layout,
};
const Editor2Html = (data) => {
    if (!data)
        return "";
    if (typeof data === "string")
        return render_paragraph({ text: data });
    let result = "";
    data.blocks.forEach((item) => {
        if (type_map[item.type])
            result += type_map[item.type](item.data);
    });
    return result;
};
exports.default = Editor2Html;
