"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HOTSPOTS_MAIN_STYLES = void 0;
exports.HOTSPOTS_MAIN_STYLES = `
    :host {
        all: initial;
    }

    a {
        text-decoration: none;
        position: absolute;
        top: 50%;
        bottom: 50%;
        margin-left: 15px;
        display: block;
        width: 15px;
        height: 15px;
        transform: translate(-50%, -50%);
    }

    [data-icon] {
        background-color: #644DED;
        position: relative;
        display: block;
        line-height: 15px;
        font-size: 12px;
        color: #FFFFFF;
    }

    [data-icon]:hover {
        cursor: pointer;
    }

    [data-icon="question"] {
        width: 15px;
        height: 15px;
        border-radius: 5px;
        text-align: center;
    }

    [data-icon="question"]::after {
        content: "?";
        font-size: 10px;
    }

    [data-icon="dot"] {
        width: 15px;
        height: 15px;
    }

    [data-icon="dot"],
    [data-icon="beacon"] {
        border-radius: 50%;
    }

    [data-icon="beacon"] {
        width: 5px;
        height: 5px;
        border: 5px solid #C1B8F8;
        // transform: translate(10px, -50%);
    }

    [data-icon="beacon"]::before {
        content: ""; 
        width: 15px;
        height: 15px;
        position: absolute;
        border-radius: 50%;
        display: block;
        top: -100%;
        left: -100%;
        pointer-events: none;
        transform: translate(-50%, -50%);
        background-color:transparent;
        box-shadow:0px 0px 1px 1px #644DED;
        -webkit-animation:active 3s infinite linear;
        animation:active 3s infinite linear;
    }

    @-webkit-keyframes active{
        0%{
          -webkit-transform:scale(.1);
          opacity:1;
        }
        100%{
          -webkit-transform:scale(4);
          opacity:0;
        }
      }
      
      @keyframes active{
        0%{
          transform:scale(.1);
          opacity:1;
        }
        100%{
          transform:scale(4);
          opacity:0;
        }
      }
      
    .adopty-icon:hover + .aw-info,
    .adopty-icon:focus + .aw-info {
        opacity: 1;
        visibility: visible;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
`;
