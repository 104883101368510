"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MODAL_STYLES = void 0;
exports.MODAL_STYLES = `
    :host {
        all: initial;
    }

    .adopty-custom {
        font-size: 16px;
        line-height: 22px;
        font-family: Arial;
        color: #000000;
    }
    .adopty-custom i {
        font-style: italic;
    }

    .adopty-custom p {
        font-size: 16px;
        line-height: 22px;
        display: block;
        padding: 0px;
        margin: 0px 0px 10px 0px;
    }

    .adopty-custom p * {
        font-size: 16px;
        line-height: 22px;
    }

    .adopty-custom h1 {
        font-size: 35px;
        line-height: 40px;
        font-weight: bold;
    }

    .adopty-custom h2 {
        font-size: 24px;
        line-height: 140%;
        font-weight: bold;
    }

    .adopty-custom h3 {
        font-size: 18px;
        line-height: 20.7px;
        font-weight: 400;
    }

    .adopty-custom h4 {
        font-size: 16px;
        line-height: 18.4px;
        font-weight: 400;
    }

    .adopty-custom h5 {
        font-size: 14px;
        line-height: 16px;
        font-weight: 400;
    }

    .adopty-custom h6 {
        font-size: 12px;
        line-height: 14px;
        font-weight: 400;
    }

    .adopty-custom h1, .adopty-custom h2, .adopty-custom h3, .adopty-custom h4, .adopty-custom h5, .adopty-custom h6 {
        padding: 0;
        margin: 0;
        margin-bottom: 8px;
    }

    .adopty-custom hr {
        display: block;
        margin: 0px 0px 10px 0px;
        position: relative;
        background-color: #DBDBDB;
        text-align: left;
        border-width: 0.5px;
        border-color: #DBDBDB;
        border-style: solid;
        opacity: 1;
    }

    .adopty-custom pre {
        font-size: 16px;
        line-height: 22px;
        display: block;
        padding: 15px;
        margin: 0px 0px 10px 0px;
        border-radius: 8px;
        background-color: #F9F9FC;
    }

    .adopty-custom blockquote {
        font-size: 16px;
        line-height: 22px;
        display: block;
        padding: 5px 0px 5px 18px;
        margin: 0px 0px 10px 0px;
        position: relative;
    }

    .adopty-custom blockquote::before {
        height: 100%;
        width: 3px;
        border-radius: 5px;
        background-color: #DBDBDB;
        display: block;
        content: "";
        position: absolute;
        left: 0;
        top: 0;
    }

    .adopty-custom blockquote * {
        font-size: 16px;
        line-height: 22px;
    }

    .adopty-custom ul {
        font-size: 16px;
        line-height: 22px;
        display: block;
        padding: 0px 0px 0px 24px;
        margin: 0px 0px 10px 0px;
    }

    .adopty-custom li {
        font-size: 16px;
        line-height: 22px;
    }

    .adopty-custom-image {
        border-radius: 3px;
        overflow: hidden;
        margin-bottom: 10px;
    }

    .adopty-custom-image img {
        max-width: 100%;
        vertical-align: bottom;
        display: block;
    }

    .adopty-custom-image--stretched .adopty-custom-image img {
        width: 100%;
    }

    .adopty-custom-image--withBorder .adopty-custom-image {
        border: 1px solid #DADAE6;
    }

    .adopty-custom-image--withBackground .adopty-custom-image {
        padding: 15px;
        background: #DADAE6;
    }

    .adopty-custom-image--withBackground img {
        max-width: 60%;
        margin: 0 auto;
    }

    .adopty-custom-layout {
        display: flex;
        width: 100%;
        gap: 10px;
        margin-bottom: 10px;
        flex-direction: row;
    }

    .adopty-custom-layout__col {
        flex: 50%;
        gap: 10px;
        padding-right: 10px;
    }

    @media (max-width: 800px) {
        .adopty-custom-layout {
            flex-direction: column;
            padding: 10px;
        }
    }

    .adopty-overlay {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 99999;
    }

    [data-visible="true"] {
        display: block;
    }

    [data-visible="false"] {
        display: none;
    }

    .aw-info-body {
        background: #FFFFFF;
        font-size: 16px;
        line-height: 22px;
        
        color: #343A40;
        box-sizing: content-box;
        border-radius: 8px;
        padding: 12px 16px;

        font-family: 'Arial';
        -moz-box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.15), 0px 0px 1px -1px rgba(0, 0, 0, 0.4);
        -webkit-box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.15), 0px 0px 1px -1px rgba(0, 0, 0, 0.4);
        box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.15), 0px 0px 1px -1px rgba(0, 0, 0, 0.4);
        border: 1px solid #DBDBDB;
    }

    .adopty-modal {
        overflow-x: hidden;
        overflow-y: auto;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 100000;
        -webkit-overflow-scrolling: touch;
        outline: 0;
        padding: 10px;
        padding-top: 40px;
    }

    @media (max-width: 800px) {
        .adopty-modal {
            padding-top: 40px;
        }
    }

    .adopty-modal-constructor {
        pointer-events: none;
        width: calc(100% - 338px);
    }

    .adopty-modal-dialog {
        width: 428px;
        max-width: 100%;
        margin: 0px auto;
        position: relative;
        pointer-events: all;
    }

    .adopty-modal[data-size="small"] .adopty-modal-dialog {
        width: 300px;
    }

    .adopty-modal[data-size="large"] .adopty-modal-dialog {
        width: 800px;
    }

    .adopty-modal[data-size="extra_large"] .adopty-modal-dialog {
        width: 1140px;
    }

    @media (min-width: 1200px) {
        .adopty-modal-dialog {
            margin: 30px auto;
        }
    }

    .adopty-modal-content {
        position: relative;
        outline: 0;
        border: 1px solid #DBDBDB;
        border-radius: 8px;
        background-color: #FFFFFF;
        -webkit-box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
        -moz-box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    }

    .adopty-tooltip {
        z-index: 100000;
        width: 428px;
        max-width: 90%;
        height: auto;
        border-radius: 10px;
        background-color: #FFFFFF;
        position: fixed;
        opacity: 1;
        font-family: 'Arial';
        -webkit-box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.15), 0px 0px 1px -1px rgba(0, 0, 0, 0.4);
        -moz-box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.15), 0px 0px 1px -1px rgba(0, 0, 0, 0.4);
        box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.15), 0px 0px 1px -1px rgba(0, 0, 0, 0.4);
        border: 1px solid #DBDBDB;
    }

    .adopty-tooltip-arrow {
        -webkit-box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.15), 0px 0px 1px -1px rgba(0, 0, 0, 0.4);
        -moz-box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.15), 0px 0px 1px -1px rgba(0, 0, 0, 0.4);
        box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.15), 0px 0px 1px -1px rgba(0, 0, 0, 0.4);
    }

    .adopty-tooltip-arrow::before {
        position: absolute;
        left: 50%;
        right: 50%;
        transform: translate(-50%, -7px);
        display: block;
        content: "";
        border-color: transparent;
        border-style: solid;
        border-right-color: #DBDBDB;
        border-width: 7px 7px 7px 0px;
    }

    .adopty-tooltip-arrow::after {
        position: absolute;
        left: 50%;
        right: 50%;
        transform: translate(-50%, -6.5px);
        display: block;
        content: "";
        border-color: transparent;
        border-style: solid;
        border-right-color: #FFFFFF;
        border-width: 7px 7px 7px 0px;
        top: 1px;
    }

    .adopty-arrow-top {
        top: calc((0.5rem + 1px) * -1);
        margin: 0.3rem 0;
    }

    .adopty-modal-closebutton {
        margin-left: auto;
        height: fit-content;
        cursor: pointer;
    }

    .adopty-modal-closebutton_outside {
        cursor: pointer;
        position: absolute;
        right: 0;
        top: -32px;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 3px 9px;
        gap: 5px;
        background: #7A7A8B;
        border-radius: 30px;
        color: #ffffff;
        font-size: 14px;
        line-height: 16px;
    }

    .adopty-modal-closebutton_outside svg {
        width: 9px;
        height: 9px;
    }

    .adopty-modal-header {
        padding: 15px;

        font-family: Arial;
        font-size: 18px;
        font-weight: 400;
        line-height: 21px;

        display: flex;
    }

    .adopty-modal-header h3 {
        margin: 0;
    }

    .adopty-modal-title {
        word-break: break-word;
    }

    .adopty-modal-body {
        padding: 15px;
    }

    .adopty-modal-footer {
        display: flex;
        justify-content: space-between;
        padding: 15px;
    }

    .adopty-btn {
        padding: 8px 15px;
        font-size: 14px;
        line-height: 16px;
        border-radius: 4px;

        color: #644DED;
        background-color: #FFFFFF;
        border: 1px solid #644DED;

        transition: 0.3s;
    }

    .adopty-btn:hover {
        cursor: pointer;
        background-color: #E8F0FF;
        border-color: #503EBE;
    }

    .adopty-btn-filled {
        border: 1px solid rgba(0, 0, 0, 0.2);
        color: #FFFFFF;
        background-color: #644DED;
    }

    .adopty-btn-filled:hover {
        border-color: #503EBE;
        background-color: #543fc9;
    }

    .adopty-btn-next {
        margin-left: auto;
    }

    .adopty-hide {
        display: none;
    }

    .adopty-embed-wrapper {
        position: relative;
        padding: 0px;
        margin: 0px 0px 10px 0px;
    }

    .adopty-embed-wrapper div {
        position: relative;
        padding-top: 56.25%;
    }

    .adopty-embed-wrapper iframe {
        position: absolute;
        inset: 0px;
        width: 100%;
        height: 100%;
    }
`;
